var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('validation-observer',{ref:"accountRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"ll-cbody"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('firstName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('First Name')}},[_c('b-form-input',{attrs:{"id":"firstName","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.userDetail.firstName),callback:function ($$v) {_vm.$set(_vm.userDetail, "firstName", $$v)},expression:"userDetail.firstName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Last Name')}},[_c('b-form-input',{attrs:{"id":"lastname","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.userDetail.lastName),callback:function ($$v) {_vm.$set(_vm.userDetail, "lastName", $$v)},expression:"userDetail.lastName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('company'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Company')}},[_c('b-form-input',{attrs:{"id":"company","disabled":true,"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.org),callback:function ($$v) {_vm.org=$$v},expression:"org"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('merchantCode'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Merchant(seller)code')}},[_c('b-form-input',{attrs:{"id":"merchantCode","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.userDetail.merchantCode),callback:function ($$v) {_vm.$set(_vm.userDetail, "merchantCode", $$v)},expression:"userDetail.merchantCode"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('accountType'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Account Type')}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"some-radios","value":"SA"},model:{value:(_vm.userDetail.accountType),callback:function ($$v) {_vm.$set(_vm.userDetail, "accountType", $$v)},expression:"userDetail.accountType"}},[_vm._v(" API Account ")]),_c('b-form-radio',{attrs:{"name":"some-radios","value":"UA"},model:{value:(_vm.userDetail.accountType),callback:function ($$v) {_vm.$set(_vm.userDetail, "accountType", $$v)},expression:"userDetail.accountType"}},[_vm._v(" User Account ")])],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Role')}},[_c('v-select',{attrs:{"id":"role","options":_vm.roleList,"label":"name","multiple":"","placeholder":"Please select a role","state":errors[0] ? false : valid ? true : null},on:{"input":_vm.selRole},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Email')}},[_c('b-form-input',{attrs:{"id":"email","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.userDetail.email),callback:function ($$v) {_vm.$set(_vm.userDetail, "email", $$v)},expression:"userDetail.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('phone'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Phone')}},[_c('b-form-input',{attrs:{"id":"phone","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.userDetail.phone),callback:function ($$v) {_vm.$set(_vm.userDetail, "phone", $$v)},expression:"userDetail.phone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('department'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Department')}},[_c('b-form-input',{staticClass:"department",attrs:{"type":"text","autocomplete":"off","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.userDetail.departmentName),callback:function ($$v) {_vm.$set(_vm.userDetail, "departmentName", $$v)},expression:"userDetail.departmentName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('password'),"rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Password')))]),_c('b-icon-question-circle',{staticClass:"ml-1",attrs:{"id":"tooltip-target-1"}}),_vm._v(" "+_vm._s(errors[0])+" "),_c('b-tooltip',{attrs:{"target":"tooltip-target-1","triggers":"hover"}},[_c('b',[_c('u',[_vm._v("Password requirements (密碼要求):")])]),_c('br'),_vm._v(" 1. 12-20 characters (密碼長度應為12-20個字符之間)"),_c('br'),_vm._v(" 2. Contain Uppercase letter, Lowercase letter, Numbers, and Symbols (必需包含大寫字母, 小寫字母, 數字, 符號) ")]),_c('div',{staticClass:"ll-pwdbox"},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors[0] ? false : valid ? true : null,"autocomplete":"new-password"},model:{value:(_vm.userDetail.password),callback:function ($$v) {_vm.$set(_vm.userDetail, "password", $$v)},expression:"userDetail.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('b-button',{staticClass:"ll-autoBtn",attrs:{"variant":"outline-info"},on:{"click":_vm.autoCreatePwd}},[_vm._v(_vm._s(_vm.$t('Auto create pwd')))])],1)],1)}}],null,true)})],1)],1)],1)],1),_c('div',{staticClass:"ll-subBtn"},[_c('b-button',{attrs:{"variant":"info","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }