var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('div',{attrs:{"id":"my-captcha-container"}}),_c('b-card',{staticClass:"mb-0 ll-loginbox"},[(_vm.host > -1)?_c('b-img',{staticStyle:{"max-width":"unset !important","width":"60%","margin-left":"20%"},attrs:{"src":require('@/assets/images/imgs/kerryexpress.svg'),"fluid":"","alt":"logo"}}):_c('b-link',{staticClass:"brand-logo"}),_c('validation-observer',{ref:"loginForm"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-provider',{attrs:{"name":_vm.$t('username'),"rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-input',{ref:"username",attrs:{"id":"username","name":"login-username","placeholder":"Username","autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.focusNext('password')}},model:{value:(_vm.userInfoForm.username),callback:function ($$v) {_vm.$set(_vm.userInfoForm, "username", $$v)},expression:"userInfoForm.username"}})],1)}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('password'),"rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Existing Password")]),_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{ref:"password",staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"name":"login-password","placeholder":"Existing Password"},model:{value:(_vm.userInfoForm.password),callback:function ($$v) {_vm.$set(_vm.userInfoForm, "password", $$v)},expression:"userInfoForm.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)],1)}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('password'),"rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"newPassword"}},[_vm._v("New Password")]),_c('b-icon-question-circle',{staticClass:"ml-1",attrs:{"id":"tooltip-target-1"}}),_vm._v(" "+_vm._s(errors[0])+" "),_c('b-tooltip',{attrs:{"target":"tooltip-target-1","triggers":"hover"}},[_c('b',[_c('u',[_vm._v("Password requirements (密碼要求):")])]),_c('br'),_vm._v(" 1. 12-20 characters (密碼長度應為12-20個字符之間)"),_c('br'),_vm._v(" 2. Contain Uppercase letter, Lowercase letter, Numbers, and Symbols (必需包含大寫字母, 小寫字母, 數字, 符號)"),_c('br'),_vm._v(" 3. Cannot be the same as any of the last 24 passwords used (不能與前24個密碼相同) ")])],1),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{ref:"newPassword",staticClass:"form-control-merge",attrs:{"id":"newPassword","type":_vm.passwordFieldType1,"name":"login-new-password","placeholder":"New Password"},model:{value:(_vm.userInfoForm.newPassword),callback:function ($$v) {_vm.$set(_vm.userInfoForm, "newPassword", $$v)},expression:"userInfoForm.newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility1}})],1)],1)],1)}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('password'),"rules":{required:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"repeatNewPassword"}},[_vm._v("Repeat the New Password")]),_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{ref:"repeatNewPassword",staticClass:"form-control-merge",attrs:{"id":"repeatNewPassword","type":_vm.passwordFieldType2,"name":"login-repeat-new-password","placeholder":"Repeat Password"},model:{value:(_vm.userInfoForm.repeatNewPassword),callback:function ($$v) {_vm.$set(_vm.userInfoForm, "repeatNewPassword", $$v)},expression:"userInfoForm.repeatNewPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility2}})],1)],1)],1)}}])}),_c('b-form-group',[(false)?_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Remember Me ")]):_vm._e()],1),_c('b-button',{style:({'cursor': _vm.disabled ? 'no-drop' : 'pointer'}),attrs:{"variant":"primary","type":"submit","block":"","disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.recaptcha($event)}}},[_vm._v(" Reset Password ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }