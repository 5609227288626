import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'


function conf(base = {}) {
  if (process.env.NODE_ENV === 'production') { // 生产环境下
    //const env = process.env.ENV_CONFIG || 'dev'
    return base.production
  } else {
    return base.dev
  }
}

const SITE_KEY = conf({
  dev: '6Lf_WGgqAAAAAFBxpPiA5rnH_Bsh4eXQsz_slQjF',
  production: '6LcGz6wqAAAAAGcQXKckxC3uBgw7Kx7XRtVT_DlG',
})

Vue.use(VueReCaptcha, {
  siteKey: SITE_KEY,
  loaderOptions: {
    useRecaptchaNet: true
  }
})