<template>
  <section>
    <!-- base info -->
    <validation-observer ref="accountRules" v-slot="{ invalid }">
        <b-card no-body>
            <b-card-body class="ll-cbody">
                <b-row>
                    <b-col md='12'>
                        <validation-provider :name="$t('firstName')" rules="required">
                            <b-form-group :label="$t('First Name')" slot-scope="{ valid, errors }">
                                <b-form-input id="firstName" v-model="userDetail.firstName" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('lastname')" rules="required">
                            <b-form-group :label="$t('Last Name')" slot-scope="{ valid, errors }">
                                <b-form-input id="lastname" v-model="userDetail.lastName" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('company')" rules="required">
                            <b-form-group :label="$t('Company')" slot-scope="{ valid, errors }">
                                <b-form-input id="company" v-model="org" :disabled="true" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('merchantCode')" rules="">
                            <b-form-group :label="$t('Merchant(seller)code')" slot-scope="{ valid, errors }">
                                <b-form-input id="merchantCode" v-model="userDetail.merchantCode"  :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('accountType')" rules="">
                            <b-form-group :label="$t('Account Type')" slot-scope="{ valid, errors }">
                               <div class="demo-inline-spacing">
                                    <b-form-radio
                                    v-model="userDetail.accountType"
                                    name="some-radios"
                                    value="SA"
                                    >
                                    API Account
                                    </b-form-radio>
                                    <b-form-radio
                                    v-model="userDetail.accountType"
                                    name="some-radios"
                                    value="UA"
                                    >
                                    User Account
                                    </b-form-radio>
                                </div>
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('role')" rules="required">
                            <b-form-group :label="$t('Role')" slot-scope="{ valid, errors }">
                                <v-select
                                    id="role"
                                    v-model="role"
                                    :options="roleList"
                                    label="name"
                                    @input="selRole"
                                    multiple
                                    placeholder="Please select a role"
                                     :state="errors[0] ? false : valid ? true : null" 
                                ></v-select>
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('email')" rules="required|email">
                            <b-form-group :label="$t('Email')" slot-scope="{ valid, errors }">
                                <b-form-input id="email" v-model="userDetail.email" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('phone')" rules="">
                            <b-form-group :label="$t('Phone')" slot-scope="{ valid, errors }">
                                <b-form-input id="phone" v-model="userDetail.phone" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('department')" rules="">
                            <b-form-group :label="$t('Department')" slot-scope="{ valid, errors }">
                                <b-form-input class="department" type="text" v-model="userDetail.departmentName" autocomplete="off" :state="errors[0] ? false : valid ? true : null" />
                                <b-form-invalid-feedback>
                                {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md='12'>
                        <validation-provider :name="$t('password')" :rules="{required:true}">
                            <b-form-group slot-scope="{ valid, errors }">
                                <label class="ll-boldText">{{ $t('Password') }}</label>
                                <b-icon-question-circle class="ml-1" id="tooltip-target-1"></b-icon-question-circle>
                                {{ errors[0] }}
                                <b-tooltip target="tooltip-target-1" triggers="hover">
                                  <b><u>Password requirements (密碼要求):</u></b><br>
                                  1. 12-20 characters (密碼長度應為12-20個字符之間)<br>
                                  2. Contain Uppercase letter, Lowercase letter, Numbers, and Symbols (必需包含大寫字母, 小寫字母, 數字, 符號)
                                </b-tooltip>
                                <div class="ll-pwdbox">
                                    <b-input-group
                                    class="input-group-merge"
                                    >
                                    <b-form-input
                                    id="password"
                                    v-model="userDetail.password"
                                    :type="passwordFieldType"
                                    class="form-control-merge"
                                    :state="errors[0] ? false : valid ? true : null" 
                                    autocomplete="new-password"
                                    />
                                    <b-input-group-append is-text>
                                    <feather-icon
                                        class="cursor-pointer"
                                        :icon="passwordToggleIcon"
                                        @click="togglePasswordVisibility"
                                    />
                                    </b-input-group-append>
                                    </b-input-group>
                                    <b-button variant="outline-info" @click="autoCreatePwd" class="ll-autoBtn">{{ $t('Auto create pwd') }}</b-button>

                                </div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <div class="ll-subBtn">
        <b-button
            variant="info"
            @click.prevent="validationForm"
            :disabled="invalid"
        >
            {{ $t('Create') }}
        </b-button>
        </div>
    </validation-observer>
    
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormRadio,
  BImg,
  BInputGroupAppend,
  BInputGroup, BIconQuestionCircle,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadio,
    vSelect,
    BImg,
    BInputGroupAppend,
    BInputGroup,
    BIconQuestionCircle
  },
  mixins: [togglePasswordVisibility],
  props: {
    // info: Object,
    orgName:{
      type:String
    },
    orgId:{
      type:Number
    }
  },
  data() {
    return {
      userDetail: {
        // username: '',
        password: '',
        email: '',
        phoneAreaCode: '',
        phone: '',
        // fullName: '',
        fullNameLocale: '',
        departmentName: '',
        company: '',
        coverImg: '',
        // sex: null,
        isEnabled: true,
        isLocked: false,
        organizationId: null,
        roleIds: [],
        firstName: '',
        lastName: '',
        accountType: 'UA',
        merchantCode: '',
      },
      role: '',
      required,
      email,
      orgList: [],
      org: '',
      roleList: [],
      isCreateUser: true,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  created() {},
  mounted() {
    this.queryOrg()
    this.queryRole()
  },
  methods: {
    generate() {
      const pasArr = ['a','b','c','d','e','f','g','h','i','j','k','l','m',
        'n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D',
        'E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U',
        'V','W','X','Y','Z','0','1','2','3','4','5','6','7','8','9','_',
        '$','%','&','@','+','!']
      let pwd = ''
      const pwdLen = pasArr.length
      for (let i = 0; i<12; i++) {
        let x = Math.floor(Math.random() * pwdLen)
        pwd += pasArr[x]
      }
      return pwd;
    },
    // 生成随机密码
    autoCreatePwd() {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#\$%\^\&*\)\(+=\/\?<>,.:;_\-|'"\[\{\]\}\\])[A-Za-z\d`~!@#\$%\^\&*\)\(+=\/\?<>,.:;_\-|'"\[\{\]\}\\]{12,20}$/
      // generate a random password, match passwordRegex
      let pwd = ""

      while (!passwordRegex.test(pwd)) {
        pwd = this.generate()
        console.log(pwd)
      }

      this.userDetail.password = pwd
    },
    // 查询组织
    queryOrg() {
      this.org = this.orgName
      this.userDetail.organizationId = this.orgId
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
        this.orgList = res.data.data
      })
    },
    selOrg(o) {
      this.userDetail.organizationId = o.id
    },
    // 查询角色
    queryRole() {
      this.$http.get('/admin/settings/role/roleList').then(res => {
        // this.roleList = res.data.data
        let arr = res.data.data
        const arr1 = arr.filter(obj=>obj.name !=='Super Admin')
        this.roleList = arr1
      })
    },
    selRole(r) {
      const arr = []
      r.map(item => {
        arr.push(item.roleId)
      })
      this.userDetail.roleIds = arr
    },
    AddUser() {
      this.$http.post('/user/createUser', this.userDetail).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create User Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$emit('isCreateUser', this.isCreateUser)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    validationForm() {
      this.$refs.accountRules.validate().then(success => {
        if (success) {
          this.AddUser()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
  },
}
</script>

<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-radioBox{
  display: flex;
  justify-content: space-evenly;
}
.ll-pwdbox{
  display: flex;
}
.ll-pwdbox>button{
  height: 2.714rem;
  width: 30%;
  margin-left: 1rem;
}
.ll-autoBtn{
  padding: 0;
}
</style>
<style>
</style>
