<template>
  <section>
    <div class="ll-topbox">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }" >
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Delivery Order') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active >
            {{ $t('Order List') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <b-button
        variant="danger"
        class="ll-cancel"
        v-permission="[141]"
        @click="downloadSelfPick"
        style="margin-right:130px">
        {{ $t('Download Self-Pick List') }}
      </b-button>
      <b-button
        variant="info"
        class="ll-cancel"
        :to="{ name: 'intermediate-tip' }"
        v-permission="[141]"      >
        {{ $t('Create new') }}
      </b-button>
    </div>

    <!-- first floor -->
    <b-card class="card-browser-states ll-fHei ll-card" >
      <b-card-body>
        <div class="row">
          <b-input-group class="ll-searchBox col-md-4">
            <b-input-group-prepend>
            <b-form-select
            v-model="numtype"
            :options="numList"
            @input="selnum"
            class="ll-numSel"
            />
            </b-input-group-prepend>
            <b-form-textarea
            v-model="trackingIDs"
            class="searchInput"
            :placeholder="placeholder"
            no-resize
            />
            <b-input-group-prepend
            is-text
            class="ll-searchBtn"
            :style="{'cursor': loadingList ? 'no-drop' : 'pointer'}"
            :disabled="loadingList"
            @click="searchById">
            <feather-icon
            icon="SearchIcon"
            class="mr-25"
            size="18"
            />
            </b-input-group-prepend>
          </b-input-group>
          <div class="col-md-2">
            {{ $t('Order Creation Date (Maximum Range: 31 Days)') }}
            <flat-pickr
              v-model="time"
              class="form-control ll-fpickr"
              :config="dateConfig"
              @on-change="onChange"
            />
          </div>
          <div class="col-md-2">
            {{ $t('Estimated Delivery Date (Maximum Range: 8 Days)') }}
            <flat-pickr
              v-model="eadDate"
              class="form-control ll-fpickr"
              :config="dateEadConfig"
              @on-change="onChangeEad"
            />
          </div>
          <div class="col-md-2">
            {{ $t('PSS Inbound Date (Maximum Range: 31 Days)') }}
            <flat-pickr
              v-model="inboundDate"
              class="form-control ll-fpickr"
              :config="dateEadConfig"
              @on-change="onChangeInb"
            />
          </div>
          <div class="col-md-2" v-if="isAccess"></div>
          <!-- Filter -->
          <div :title="$t('Customer')" class="col-md-2 ll-filter" v-permission="['Choose Org']" :style= "[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('Customer') }}
<!--              <span v-if="isAccess2 == false">{{ $t('Customer') }}</span>-->
              <v-select
              v-model.trim="customer"
              :options="customerList"
              label="value"
              @search="queryCustomer"
              @input="getCustomer"
              placeholder="Select Customer"
              multiple
              >
              </v-select>
          </div>
          <div :title="$t('Status')" class="col-md-2 ll-filter" :style= "[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('Tracking Status') }}
<!--              <span v-if="isAccess2 == false">{{ $t('Status') }}</span>-->
              <v-select
              id="status"
              v-model="latestOrderStatus1"
              :options="statusList"
              label="name"
              @input="selStatus"
              placeholder="Select Status"
              multiple
              ></v-select>
          </div>
          <div :title="$t('Tags')" class="col-md-2 ll-filter" :style= "[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('Order Tags') }}
<!--              <span v-if="isAccess2 == false">{{ $t('Tags') }}</span>-->
              <v-select
              id="tags"
              v-model="tags"
              multiple
              :options="tagsList"
              label="codeDescription"
              @input="selTag"
              placeholder="Select Order Tags"
              ></v-select>
          </div>
          <div :title="$t('Service Party')" class="col-md-2 ll-filter" v-if="isAccess" :style= "[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('Service Party') }}
<!--              <span v-if="isAccess2 == false">{{ $t('Service Party') }}</span>-->
              <v-select
              id="serviceParty"
              v-model="serviceParty"
              :options="sPartyList"
              @input="selSParty"
              label="codeDescription"
              placeholder="Select Service Party"
              multiple
              ></v-select>
          </div>
          <div :title="$t('Order Delivery Type')" class="col-md-2 ll-filter" :style= "[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('Order Delivery Type') }}
              <v-select :options="typeList" label="key" @input="selType" v-model="condition.deliveryType" placeholder="Select Order Delivery Type" return-object>
                <template slot="option" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  Order Delivery Type - {{ data.des }}
                </template>
                <template slot="selected-option" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  Order Delivery Type - {{ type }}
                </template>
              </v-select>
          </div>
          <div :title="$t('Customer Type')" class="col-md-2 ll-filter" v-if="isAccess" :style= "[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('Customer Type') }}
<!--              <span v-if="isAccess2 == false">{{ $t('Customer Type') }}</span>-->
              <v-select
              v-model="cusType"
              :options="cusTypeList"
              label="codeDescription"
              @input="selCusType"
              placeholder="Select Customer Type"
              multiple
              ></v-select>
          </div>
          <div :title="$t('Customer Type')" class="col-md-2 ll-filter" v-if="isAccess" :style= "[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('Delivery Area') }}
<!--            <span v-if="isAccess2 == false">{{ $t('Customer Type') }}</span>-->
            <v-select
              :options="areaList"
              label="routeArea"
              v-model="area"
              placeholder="Select Delivery Area"
              @input="getArea"
            ></v-select>
          </div>
          <div :title="$t('Customer Type')" class="col-md-2 ll-filter" v-if="isAccess" :style= "[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('Sort Code') }}
<!--            <span v-if="isAccess2 == false">{{ $t('Customer Type') }}</span>-->
            <v-select
              v-model="sortCode"
              :options="routeCodeList"
              label="sortingCode"
              @input="getRouteCode"
              placeholder="Select Sort Code"
              multiple
            ></v-select>
          </div>
          <div class="col-md-2 ll-filter" v-if="isAccess" :style= "[isAccess2 ? {'margin-top': '15px'} : {'margin-top': '15px'}]">
            {{ $t('SC Sort Code') }}
<!--            <span v-if="isAccess2 == false">{{ $t('Customer Type') }}</span>-->
            <v-select
              v-model="scSortCode"
              :options="scSortCodeList"
              label="scSortingCode"
              @input="getScSortCode"
              placeholder="Select SC Sort Code"
              multiple
            ></v-select>
          </div>
          <div class="col-md-6 pt-1 ll-filter">
            <div class="ll-btn">
              <b-button variant="secondary" @click="searchById" class="mr-1 btn-icon">
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
              <b-dropdown
                :text="$t('Batch Operation')"
                variant="info"  
                class="ll-batch my-0 ml-0 p-0"
              >
                <b-dropdown-item @click="batchRegenerateSFLabel">{{$t('Regenerate SF Label and Push to PSS')}}</b-dropdown-item>
                <b-dropdown-item @click="batchDownload">{{$t('Download Label')}}</b-dropdown-item>
                <b-dropdown-item @click="batchDownloadPDF">{{$t('Download Label in One PDF')}}</b-dropdown-item>
<!--                <b-dropdown-item @click="batchDownloadSignature" v-permission="['OrderList:downloadReport']">{{$t('Download Signature')}}</b-dropdown-item>-->
                <b-dropdown-item @click="batchDownloadSignature">{{$t('Download Signature')}}</b-dropdown-item>
                <b-dropdown-item @click="updateSortCode" v-if="isAccess">{{$t('Update')}} {{'Sort Code'}}</b-dropdown-item>
<!--                <b-dropdown-item @click="statusReport" v-permission="['OrderList:downloadReport']">{{$t('Status')}} {{$t('Report')}}</b-dropdown-item>-->
                <b-dropdown-item @click="statusReport">{{$t('Status')}} {{$t('Report')}}</b-dropdown-item>
                <b-dropdown-item @click="requestRSS" v-permission="['OrderList:editRoutingCode']">Batch Request RSS</b-dropdown-item>
<!--                <b-dropdown-item @click="orderDetailReport" v-permission="['OrderList:downloadReport']">{{$t('Order Detail Report')}}</b-dropdown-item>-->
                <b-dropdown-item @click="orderDetailReport">{{$t('Order Detail Report')}}</b-dropdown-item>
                <b-dropdown-item @click="debugUse"  v-permission="['Debug use']" >{{$t('Debug use')}}</b-dropdown-item>
                <b-dropdown-item @click="orderDetailReportV2"  v-permission="['Debug use']" >{{$t('OrderDetailReportV2')}}</b-dropdown-item>
              </b-dropdown>
              <b-button
              variant="secondary"
              class="mr-1"
              @click="onClear"
              >
              {{ $t('Clear') }}
              </b-button>
              <b-button variant="primary"
              @click="searchById">
              {{ $t('Search') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-table
      ref="selectableTable"
      class="position-relative ll-table"
      :items="rows"
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      selectable
      @row-selected="onRowSelected"
      :select-mode="selectMode"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      :sticky-header="true"
      :busy="isBusy"
    >
    <template v-slot:head(selected)="data">
          <b-form-checkbox @input="selectAllRowsData">
            </b-form-checkbox>
      </template>
    <template v-slot:cell(selected)="{rowSelected, index}">
          <b-form-checkbox :checked="rowSelected?true:false" @change="selectRowData(rowSelected, index)">
          </b-form-checkbox>
      </template>
      <template v-slot:cell(package)='row'>
        <feather-icon
              icon="DatabaseIcon"
              size="20"
              v-if="(row.item.orderQty !== 1 && row.item.orderQty !== null && row.item.jobStatus === 'ORDER_RECEIVED') "
              color="#409eff"
              />
            <feather-icon
              icon="DatabaseIcon"
              size="20"
              v-if="(row.item.orderQty !== 1 && row.item.orderQty !== null && row.item.jobStatus === 'COMP') "
              color="#28c76f"
            />
            <feather-icon
              icon="DatabaseIcon"
              size="20"
              v-if="(row.item.orderQty !== 1 && row.item.orderQty !== null && row.item.jobStatus === 'PROD') "
              color="#ff9f43"
            />
            <feather-icon
              icon="DatabaseIcon"
              size="20"
              v-if="(row.item.orderQty !== 1 && row.item.orderQty !== null && row.item.jobStatus === 'DIP') "
              color="#ea5455"
            />
            <feather-icon
              icon="BoxIcon"
              size="20"
              v-if="((row.item.orderQty === 1 || row.item.orderQty === null)&& row.item.jobStatus === 'ORDER_RECEIVED') "
              color="#409eff"
            />
            <feather-icon
              icon="BoxIcon"
              size="20"
              v-if="((row.item.orderQty === 1 || row.item.orderQty === null)&& row.item.jobStatus === 'COMP') "
              color="#28c76f"
            />
            <feather-icon
              icon="BoxIcon"
              size="20"
              v-if="((row.item.orderQty === 1 || row.item.orderQty === null) && row.item.jobStatus === 'PROD') "
              color="#ff9f43"
            />
            <feather-icon
              icon="BoxIcon"
              size="20"
              v-if="((row.item.orderQty === 1 || row.item.orderQty === null) && row.item.jobStatus === 'DIP') "
              color="#ea5455"
            />
      </template>
      <template v-slot:cell(customerOrderNo)='row'>
        <span style="color:#ff9f43;cursor: pointer;" @click="onDetail(row.item)">{{ row.item.customerOrderNo }}</span>
      </template>
      <template v-slot:cell(jobStatus)='row'>
        <b-badge
          pill
          class="ll-badge"
          variant="success"
          v-if="row.item.jobStatus === 'COMP'"
        >
          <span>Completed</span>
        </b-badge>
        <b-badge
          pill
          class="ll-badge"
          variant="light-info"
          v-if="row.item.jobStatus === 'ORDER_RECEIVED'"
        >
          <span>Order received</span>
        </b-badge>
        <b-badge
          pill
          class="ll-badge"
          variant="light-warning"
          v-if="row.item.jobStatus === 'PROD'"
        >
          <span>On Warehouse</span>
        </b-badge>
        <b-badge
          pill
          class="ll-badge"
          variant="light-danger"
          v-if="row.item.jobStatus === 'DIP'"
        >
          <span>Delivery in progress</span>
        </b-badge>
      </template>
      <template v-slot:cell(tag)='row'>
        <b-badge
          pill
          class="ll-badge badge-tag"
          v-for="(val,idx) in row.item.tag" :key="idx"
        >
        <span>{{val?val:null}}</span>
        </b-badge>
      </template>
      <template v-slot:cell(label)='row'>
        <b-link :href="baseURL + '/label/downLoadFile/' + row.item.tkID" target="_blank">
          <svg class="icon ll-imgs" aria-hidden="true">
            <use xlink:href="#icon-Waybill"></use>
          </svg>
        </b-link>
      </template>
    </b-table>
    <div class="d-flex justify-content-between flex-wrap mt-1">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <b-modal
      id="modal-details"
      ref="modal-details"
      centered
      title=""
      size="lg"
      hide-footer
    >
      <div class="flex-grow-1">
        <b-card class="card-browser-states ll-backg ll-sHei ll-cardHei1">
          <b-card-body>
            <div class="tag-set">
              <b-badge
                pill
                class="ll-badge badge-tag"
                v-for="(item ,index) in jobTags"
                :key="index"
              >
              {{item}}
                <!-- <span>No Sort Code</span> -->
              </b-badge>
          </div>
          <b-tabs
            pills
            class="ll-tabs"
            v-if="selectedItem"
          >
            <!-- Tab: Overview -->
            <b-tab active>
              <template #title>
                <span class="d-none d-sm-inline">{{ $t('Overview') }}</span>
              </template>
              <overview :orderItem="selectedItem"
                :jobDetails1="jobDetails1"
                :jobDetails2="jobDetails2"
                :jobDetails3="jobDetails3"
                :jobDetails4="jobDetails4"
              ></overview>
            </b-tab>
            <!-- Tab: Item -->
            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">{{ $t('Item') }}</span>
              </template>
              <item
                :jobDetails1="jobDetails1"
                :jobDetails2="jobDetails2"
                :jobDetails5="jobDetails5"
              >
              </item>
            </b-tab>

            <!-- Tab: Operation -->
            <b-tab v-if="isAccess">
              <template #title>
                <span class="d-none d-sm-inline" v-permission="['OrderList:detailView']">{{ $t('Operation') }}</span>
              </template>
              <operation
                :jobDetails1="jobDetails1"
                :jobDetails2="jobDetails2"
                :jobDetails3="jobDetails3"
                @isDelOk="isDelOk"
              ></operation>
            </b-tab>
            <!-- Tab: Package -->
            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">{{ $t('Package') }}</span>
              </template>
              <package
                :packageInfo="packageInfo"
              ></package>
            </b-tab>
          </b-tabs>
          </b-card-body>
        </b-card>
      </div>
    </b-modal>
    <b-modal
      id="modal-uploadorder"
      centered
      title=""
      size="lg"
      hide-footer
    >
      <upload-order></upload-order>
    </b-modal>
    <b-modal
      id="modal-batchupdate"
      centered
      title=""
      size="lg"
      hide-footer
    >
      <batch-update></batch-update>
    </b-modal>
    <b-modal
      id="modal-inboundreport"
      centered
      title=""
      size="lg"
      hide-footer
    >
      <inbound-report></inbound-report>
    </b-modal>
    <b-modal
      id="modal-batchdownload"
      centered
      title=""
      size="lg"
      hide-footer
    >
      <batch-download></batch-download>
    </b-modal>
    <b-modal
      id="modal-cusorder"
      centered
      title=""
      size="lg"
      hide-footer
    >
      <cus-order></cus-order>
    </b-modal>
    <!-- 批量修改sort code -->
    <b-modal
      id="modal-batchSortCode"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="isBatchSortCode"
      size="lg"
      :no-close-on-backdrop="true"
      @hidden="sortCodeRows=[];getList()"
    >
      <b-table
        class="position-relative"
        :items="sortCodeRows"
        :fields="sortCodeColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :striped="true"
        :hover="false"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :no-border-collapse="true"
        sticky-header="60vh"
      >
        <template #cell(tkID)="row">
          <span class="font-weight-bold">{{row.value}}</span>
        </template>
        <template #cell(cneeAddressStr)="row">
          <div style="display:flex;justify-content:space-between">
            <div>{{row.value}}</div>
            <b-button variant="info" @click="copyAddress(row.value)">copy</b-button>
          </div>
        </template>
        <template #cell(sortCode)="row">
          <div class="d-flex justify-content-between mb-2">
            <span>{{row.item.deliveryType ? $t('Home delivery') : $t('Home delivery')}}</span>
            <b-form-checkbox
              class="custom-control-info ll-switch"
              name="check-button"
              switch
              v-model="row.item.ischeck"
              checked="true"
              @input="selhd(row,row.item.ischeck)"
            />
            <span>{{ $t('Self-pick') }}</span>
          </div>
          <b-form-group
            :label="$t('Select a delivery area')"
            label-for="area"
            v-if="row.item.ishd"
          >
          <!-- {{test}} -->
            <v-select
              :options="areaList"
              label="routeArea"
              v-model="row.item.area1"
              placeholder="Please select area"
              @input="getArea1(row, row.item.area1)"
            ></v-select>
          </b-form-group>
          <b-form-group
            :label="$t('Select a routing code')"
            label-for="routeCode"
            v-if="row.item.ishd"
          >
            <v-select
              :options="routeCodeList1"
              label="sortingCode"
              v-model="row.item.sortCode1"
              placeholder="Please select routing code"
              @input="getRouteCode1(row,row.item.sortCode1)"
            ></v-select>
          </b-form-group>
          <b-form-group
            :label="$t('Select a vendor')"
            label-for="vendor"
            v-if="row.item.issp"
          >
          <!-- {{test}} -->
            <v-select
              :options="vendorList"
              label="agent"
              v-model="row.item.vendor"
              placeholder="Please select vendor"
              @input="getVendor(row, row.item.vendor)"
            ></v-select>
          </b-form-group>
          <b-form-group
            :label="$t('Location code')"
            label-for="district"
            v-if="row.item.issp"
          >
            <v-select
              :options="locCodeList"
              label="locationID"
              v-model="row.item.locCode"
              @input="selCode(row, row.item.locCode)"
              @open="selOpen(row, row.item.locCode)"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Location name')"
            label-for="area"
            v-if="row.item.issp"
          >
            <b-form-input v-model="row.item.locName"/>
          </b-form-group>
        </template>
      </b-table>
      <template #modal-footer>
        <b-button
          variant="primary"
          class="btn"
          @click="batchSortcode"
        >
          {{ $t('Save') }}
        </b-button>
      </template>
    </b-modal>
    <!-- 批量修改sort code 进度-->
    <b-modal
      id="modal-batchUpdate"
      cancel-variant="outline-secondary"
      ok-title=""
      centered
      title=""
      v-model="isBatchUpdate"
      size="lg"
      hide-footer
    >
      <b-table :items="items" :fields="fields"
        :borderless='false' :hover="true"
        :sticky-header="true"
        class="ll-table"
      >
        <template #cell(id)="scope">
          <span>{{scope.item.progressMax - scope.item.progressNow}}</span>
        </template>
        <template #cell(progressNow)="scope">
          <b-progress
            key="info"
            :max="scope.item.progressMax"
            :variant="(scope.item.progressStatus === 'success') ? 'info': 'danger'"
            class="progress-bar-info"
          >
            <b-progress-bar
              :value="scope.item.progressNow"
              variant="info"
              :label="`${((scope.item.progressNow/scope.item.progressMax) * 100).toFixed(2)}%`"
            />
            <span class="ll-fail" v-if="scope.item.progressStatus !== 'success'">0%</span>
          </b-progress>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="modal-debugUse"
      centered
      title="Debug use"
      size="md"
      hide-footer
      v-model="isDebugUse"
    >
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col>
              Service
              <v-select
              :options="debugUseList"
              label="frontEndname"
              v-model="debugUser"
              placeholder="Select Service"
            ></v-select>
            </b-col>
          </b-row>
          <footer style="margin-top:20px;display:flex;justify-content:flex-end">
              <b-button
                  variant="primary"
                  class="btn"
                  :disabled="!debugUser"
                  @click="saveDebug"
              >
                  {{ $t('Save') }}
              </b-button>
          </footer>
        </b-card-body>
      </b-card>
    </b-modal>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BFormTextarea,
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, VBToggle, BTab, BTabs,
  BPagination, BFormSelect, BAvatar, BFormCheckbox, BFormSelectOption, BModal, VBModal,
  BDropdown, BDropdownItem, BOverlay, BCollapse, BSpinner, BLink,
  BProgress, BProgressBar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'
import Overview from './components/overview.vue'
import Operation from './components/operation.vue'
import Package from './components/package.vue'
import Item from './components/item.vue'
import { debounce } from "@/libs/fun.js"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import permission from '@core/directives/permission/index.js'
import uploadOrder from './uploadOrder.vue'
import axios from 'axios'

export default {
  components: {
    BProgress,
    BProgressBar,
    uploadOrder,
    Overview,
    Operation,
    Package,
    Item,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCollapse,
    BBadge,
    BButton,
    BLink,
    BSpinner,
    BTable,
    flatPickr,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BTab,
    BTabs,
    BPagination,
    BFormSelect,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BFormSelectOption,
    BModal,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-modal': VBModal,
    permission,
  },
  data() {
    return {
      selectMode: 'multi',
      window: {
        width: 0,
        height: 0
      },
      debugUseList:[{frontEndname:'Re-Push Order to SF'},{frontEndname:'Re-Push Order to EPOD'},],
      disabled:true,
      debugUser:'',
      // statusList: ['REC', 'PROD', 'DIP', 'COMP', 'TRA'],
      statusList: [
        { name: 'Order received', code: 'REC' },
        { name: 'In transit', code: 'TRA' },
        { name: 'On Warehouse', code: 'PROD' },
        { name: 'Delivery in progress', code: 'DIP' },
        { name: 'Completed', code: 'COMP' },
      ],
      trackingIDs: '',
      dateConfig: {
        dateFormat: 'Y-m-d H:i:ss',
        mode: 'range',
        defaultDate: [(new Date).setDate((new Date).getDate() - 2), new Date()],
        // enableTime: true, // 启用时间选择器
      },
      dateEadConfig: {
        dateFormat: 'Y-m-d',
        mode: 'range',
        defaultDate: null,
        // enableTime: true, // 启用时间选择器
      },
      condition: {
        page: '1',
        size: '100',
        shipmentDateFrom: null,
        shipmentDateTo: null,
        serviceParty: null,
        orderBy: 'createdAt',
        sequence: 'desc',
        tags: null,
        latestOrderStatus: null,
        trackingIDs: null,
        // orgId: 'org006',
        orgName: null,
        deliveryType: null,
        orderIds: null,
        receiverPhone:null,
        isAmazon: false,
        customerType: null,
        sortCode: null,
        udf1: null,
        eadDateFrom: null,
        eadDateTo: null,
        orgIds:null,
        inboundDateFrom:null,
        inboundDateTo:null,
      },
      time: '',
      eadDate: '',
      inboundDate:'',
      isSelectingTime: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200, 500],
      page: null,
      size: 100,
      totalSize: null,
      totalPage: null,
      tableHeaders: [
        { label: '', prop: "package", width: "80" },
        { label: this.$t('Order date'), prop: "createdAt", width: "200" },
        { label: this.$t('Customer Name'), prop: "displayName", width: "180" },
        { label: this.$t('Order No'), prop: "customerOrderNo", width: "auto" },
        { label: this.$t('Status'), prop: "jobStatus", width: "200" },
        { label: this.$t('Tracking Number'), prop: "tkID", width: "auto" },
        { label: this.$t('Tag'), prop: "tag", width: "auto" },
        { label: this.$t('UDF'), prop: "udf1", width: "auto" },
        { label: this.$t('Est. Delivery'), prop: "pkEadDate", width: "150" },
        { label: this.$t('Package qty'), prop: "orderQty", width: "150" },
        { label: this.$t('Label'), prop: "label", width: "80" },
      ],
      rows: [],
      orderStatus: '',
      orderStatusCode: {
        rec: 0, // Order received
        prod: 0, // On Warehouse
        dip: 0, // Delivery in progress
        comp: 0, // Completed
      },
      tkid: '',
      orderId: '',
      selected: [],
      color: 'info',
      color1: 'primary',
      color2: 'danger',
      color3: 'warning',
      tagsList: [],
      sPartyList: [],
      serviceParty: '',
      windowWidth: document.documentElement.clientWidth,  //屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //屏幕高度
      height: document.documentElement.clientHeight*0.67,
      hei: (document.documentElement.clientHeight*0.67-46)/10,
      heiVal: null,
      hei1: document.documentElement.clientHeight*0.12,
      numList: ['Reference Number', 'Tracking Number','3RD Tracking Number', 'User Define Value'],
      numtype: 'Reference Number',
      sonList: [],
      filterBg: {
        '--background': '#fff'
      },
      customerList: [],
      customer: '',
      typeList: [
        { key: 'HD', des: 'Home Delivery' },
        { key: 'SP', des: 'Self Pick' },
        { key: 'SD', des: 'Same Day Delivery' }
      ],
      pkg: false,
      type: null,
      placeholder: 'Enter Order Number to search',
      searchType: 'Reference Number',
      nums: 1,
      idx: null,
      tags: null,
      latestOrderStatus1: '',
      ischeck: false,
      isAllCheck: false,
      isAllCheck1: false,
      check1: true,
      check2: false,
      arrBox: [],
      loadingList: false,
      selectedItem: null,
      jobDetails1: null,
      jobTags:null,
      jobDetails2: null,
      jobDetails3: null,
      jobDetails4: null,
      packageInfo:null,
      jobDetails5: null,
      domObj: null,
      isTbox: true,
      cusTypeList: [],
      cusType: '',
      isBusy: true,
      baseURL: axios.defaults.baseURL,
      isAccess: true,
      isAccess2: true,
      areaList: [],
      area: null,
      area1: null,
      routeCodeList: [],
      sortCode: null,
      routeCodeList1: [],
      sortCode1: null,
      isBatchSortCode: false,
      sortCodeRows: [],
      sortCodeColumns: [
        { key: 'createdAt', label: this.$t('Order date') },
        { key: 'customerName', label: this.$t('Customer') },
        { key: 'tkID', label: this.$t('Tracking Number')},
        { key: 'cneeAddressStr', label: this.$t('Address')},
        { key: 'sortCode', label: this.$t('Sort Code'), class: 'll-sortCodeBox'},
      ],
      ishd: true,
      issp: false,
      vendorList: [],
      vendor: null,
      locCodeList: [],
      locName: null,
      isLocCode: false,
      isLocName: false,
      isRcode: false,
      paramsList: null,
      paramsList1: null,
      isBatchUpdate: false,
      isDebugUse:false,
      items: [],
      fields: [
        { key: 'createdAt', label: this.$t('Upload time') },
        { key: 'operator', label: this.$t('Operator') },
        { key: 'taskName', label: this.$t('Upload type') },
        { key: 'progressNow', label: this.$t('Status'), class: 'll-progress' },
        { key: 'progressMax', label: this.$t('Total orders') },
        { key: 'id', label: this.$t('Error orders') },
        // { key: 'input', label: this.$t('Download source file') },
        // { key: 'output', label: this.$t('Download Error message') },
      ],
      test: '',
      tkIds: [],
      orgIds:[],
      rssList: [],
      selectAll: false,
      scSortCode: null,
      scSortCodeList: [],
      rows:[],
      columns: [
        { key: 'selected', label: 'selected'},
        { key: 'package',label: '',  width: "80" },
        { key: 'createdAt',label: this.$t('Order Creation date'),  width: "200" },
        { key: 'customerName',label: this.$t('Shipper Name'),  width: "180" },
        { key: 'customerOrderNo',label: this.$t('Reference Number'),  width: "auto" },
        { key: 'jobStatus',label: this.$t('Tracking Status'),  width: "200" },
        { key: 'tkID',label: this.$t('Tracking Number'),  width: "auto" },
        { key: 'tag',label: this.$t('Order Tags'),  width: "auto" },
        { key: 'udf1',label: this.$t('User Define Value'), width: "auto" },
        { key: 'pkEadDate',label: this.$t('Estimated Delivery Date'),  width: "150" },
        { key: 'orderQty',label: this.$t('Package Quantity'),  width: "150" },
        { key: 'label',label: this.$t('Download Waybill Label'), width: "80" },
      ],
    }
  },
  computed: {
  },
  watch: {
    // isBatchUpdate(val) {
    //   if (val) {
    //     this.getProcessStatus()
    //     this.timer = setInterval(() => {
    //       setTimeout(() => {
    //         // setTimeout是自带清除定时器
    //         this.getProcessStatus()
    //       }, 0)
    //     }, 5000)
    //   }
    // }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.getList()
    this.configTags()
    this.querySparty()
    this.queryCustomer()
    this.queryCusType()
    this.getAccess()
    this.queryArea()
    this.queryVendorList()
    this.queryLocCode()
    // this.queryRouting()
    this.queryRouteCode1()
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        window.fullWidth = document.documentElement.clientWidth
        this.windowHeight = window.fullHeight
        this.windowWidth = window.fullWidth
      })()
    }
  },
  beforeDestroy() {
    if (!this.domObj) return
    if (this.domObj.attachEvent) {
      this.domObj.detachEvent('onmousewheel', this.mouseScroll)
    }
    if (this.domObj.addEventListener) {
      this.domObj.removeEventListener('DOMMouseScroll', this.mouseScroll, false)
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
      this.sortCodeRows = items
      const arr = []
      const arr1= []
      const arr2= []
      const arr3 = []
      items.map(i=>{
        const obj = {}
        obj.customerOrderNo = i.customerOrderNo
        obj.tkID = i.tkID
        obj.labelUrl= (i.updateLabelUrl!== null) ? i.updateLabelUrl : i.labelUrl
        obj.labelUrl= (i.updateLabelUrl!== null) ? i.updateLabelUrl : i.labelUrl
        obj.orderQty = i.orderQty
        arr.push(obj)
        arr1.push(i.id)
        arr2.push(i.tkID)
        arr3.push(i.customerOrderNo)
        const obj1= {}
        obj1.cneeAddressStr = i.cneeAddressStr
        obj1.cneeCity = i.cneeCity
        obj1.cneeState = i.cneeState
        obj1.updAddress = i.updAddress
        obj1.cneeCountry = i.cneeCountry
        obj1.tkID = i.tkID
        this.rssList.push(obj1)
      })
      this.arrBox = arr
      this.paramsList1 = arr1
      this.tkIds = arr2
      this.orgIds = arr3
    },
    selectRowData(rowSelected, index){
      if(rowSelected == false){
        this.$refs.selectableTable.selectRow(index)
      }else{
        this.$refs.selectableTable.unselectRow(index)
      }
    },
    selectAllRowsData(val) {
      this.selectAll = val ? true : false
        if (val == true) {
          this.$refs.selectableTable.selectAllRows()
        }
        if(val == false){
          this.$refs.selectableTable.clearSelected()
        }
        
      },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    handleSelection(val) {
      this.selectAll = false
      this.sortCodeRows = val
      const arr = []
      const arr1= []
      const arr2= []
      const arr3 = []
      val.map(i=>{
        const obj = {}
        obj.customerOrderNo = i.customerOrderNo
        obj.tkID = i.tkID
        obj.labelUrl= (i.updateLabelUrl!== null) ? i.updateLabelUrl : i.labelUrl
        obj.orderQty = i.orderQty
        arr.push(obj)
        arr1.push(i.id)
        arr2.push(i.tkID)
        arr3.push(i.customerOrderNo)
        const obj1= {}
        obj1.cneeAddressStr = i.cneeAddressStr
        obj1.cneeCity = i.cneeCity
        obj1.cneeState = i.cneeState
        obj1.updAddress = i.updAddress
        obj1.cneeCountry = i.cneeCountry
        obj1.tkID = i.tkID
        this.rssList.push(obj1)
      })
      this.arrBox = arr
      this.paramsList1 = arr1
      this.tkIds = arr2
      this.orgIds = arr3
    },
    handleSelectAll(val) {
      this.selectAll = val ? true : false
    },
    batchSortcode() {
      let arr = []
      this.sortCodeRows.map(i=>{
        let obj = {}
        obj.trackingNumber = i.tkID
        obj.id = i.id
        obj.serviceParty = i.vendor
        if (i.deliveryType == 'SP') {
          obj.isSelfPick = true
          if (i.locCode) {
            obj.locationID = i.locCode
          } else {
            obj.locationID = null
          }
          if (obj.locationID == i.sortCode) {
            return
          }
        } else {
          obj.isSelfPick = false
          if (i.sortCode1) {
            obj.updateSortCode = i.sortCode1
          } else {
            obj.updateSortCode = null
          }
          if (obj.updateSortCode == i.sortCode) {
            return
          }
        }
        arr.push(obj)
      })
      this.paramsList = arr
      // console.log(this.paramsList)
      this.$http.post('/jobkes/batchEditRoutingCode', this.paramsList).then(res=>{
        if (res.data.code == '200') {
          this.isBatchSortCode = false
          // this.isBatchUpdate = true
          this.$router.push({ name: 'batch-update' })
        }
      })
    },
    isDelOk(val){
      if(val){
        this.$refs['modal-details'].hide()
        this.getList()
      }
    },
    selhd(d1, d2) {
      this.sortCodeRows.map(i=>{
        if (d2) {
          this.sortCodeRows[d1.index].deliveryType = 'SP'
          this.sortCodeRows[d1.index].ishd = false
          this.sortCodeRows[d1.index].issp = true
        } else {
          this.sortCodeRows[d1.index].deliveryType = 'HD'
          this.sortCodeRows[d1.index].ishd = true
          this.sortCodeRows[d1.index].issp = false
        }
      })
    },
    updateSortCode() {
      if (this.sortCodeRows.length != 0) {
        this.isBatchSortCode = true
        this.$http.post('/jobkes/getBatchIds', this.paramsList1).then(res=>{
          if (res.data.code == '200') {
            let arr = res.data.data
            let arr1 = this.sortCodeRows.map((item,index)=>{
              let array={createdAt:item.createdAt,customerName:item.customerName,tkID:item.tkID,cneeAddressStr:item.cneeAddressStr,sortCode:item.sortCode,id:item.id}
              // return {...item,...arr[index]}
              return array
            })
            // this.sortCodeRows = arr1


            arr.map((item)=>{
                arr1.map((item1)=>{
                    if(item.id == item1.id){
                        Object.assign(item,item1);
                        return item;
                    }
                })
            })
            console.log('1',arr)
            this.sortCodeRows = arr
        this.sortCodeRows.map((item,index)=>{
          console.info(item.deliveryType,'0---')
          if (item.deliveryType == 'SP') {
            item.ischeck = true
            item.issp = true
            item.ishd = false
            if (item.sortCode){
              this.sortCodeRows[index].vendor = this.sortCodeRows[index].agent
              this.sortCodeRows[index].locCode = this.sortCodeRows[index].sortCode
              this.sortCodeRows[index].locName = this.sortCodeRows[index].storename
              item.isLocCode = true
              item.isLocName = true
            } else {
              item.isLocCode = false
              item.isLocName = false
            }
          } else {
            item.ischeck = false
            item.issp = false
            item.ishd = true
            if (item.sortCode){
              this.sortCodeRows[index].area1 = this.sortCodeRows[index].routeArea
              this.sortCodeRows[index].sortCode1 = this.sortCodeRows[index].sortCode
              item.isRcode = true
            } else {
              item.isRcode = false
            }
          }
        })
          }
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    queryVendorList() {
      this.$http.get('selfpickLocation/getAgentList').then(res=>{
        this.vendorList = res.data.data
      })
    },
    getVendor(d1,d2) {
      this.sortCodeRows[d1.index].locCode = null
      this.sortCodeRows[d1.index].locName = null
      if (d2&&d2.agent) {
        this.sortCodeRows[d1.index].vendor = d2.agent?d2.agent:null
        this.sortCodeRows[d1.index].isLocCode = true
        this.sortCodeRows[d1.index].isLocName = true
        this.queryLocCode(d2.agent)
      }else{
         this.queryLocCode(d1.item.vendor)
      }
      
      this.test = null
    },
    queryLocCode(d) {
      this.$http.get('selfpickLocation/getListBySearch',{params: {agent:d}}).then(res=>{
        this.locCodeList = res.data.data
      })
    },
    selOpen(d1,d2){
      this.queryLocCode(d1.item.vendor)},
    selCode(d1,d2) {
      this.getVendor(d1,d2)
      if (d2&&d2.agent) {
        this.sortCodeRows[d1.index].locCode = d2.locationID
        this.sortCodeRows[d1.index].locName = d2.storename
      }
      
    },
    copyAddress(context){
      navigator.clipboard.writeText(context).then(()=>{
        this.$message({type:'success',message:'Copy successfully'})
      })
    },
    queryLocName() {
      this.$http.get('selfpickLocation/getListBySearch', {params: {agent:this.pro, locationID:this.locCode}}).then(res=>{
        this.locName = res.data.data[0].storename
      })
    },
    queryArea() {
      this.$http.get('routeList/getRouteAreaList').then(res => {
        // this.areaList = res.data.data
        const arr = res.data.data
        arr.map(i=>{
          if (i) {
            this.areaList.push(i)
          }
        })
      })
    },
    getArea(s) {
      this.area = s.routeArea
      if (this.area !== null) {
        this.queryRouteCode()
        this.sortCode = null
      }
    },
    getArea1(d1,d2) {
      if (d2) {
        this.sortCodeRows[d1.index].area1 = d2.routeArea
        if (d2.routeArea) {
          this.sortCodeRows[d1.index].isRcode = true
        }
        this.sortCodeRows[d1.index].sortCode1 = null
        this.queryRouteCode1(d2.routeArea)
        this.test = undefined
      }else{
        this.queryRouteCode1()
      }
      
      
    },
    queryRouteCode() {
      const data = {}
      data.routeArea = this.area
      this.$http.post('routeList/getDistrictAndSortCodeList', data).then(res => {
        // this.routeCodeList = res.data.data
        const arr = res.data.data
        arr.map(i=>{
          this.routeCodeList.push({sortingCode: i.sortingCode})
          this.scSortCodeList.push({scSortingCode: i.scSortingCode})
        })
      })
    },
    queryRouteCode1(d) {
      const data = {}
      data.routeArea = d?d:''
      this.$http.post('routeList/getDistrictAndSortCodeList', data).then(res => {
        this.routeCodeList1 = res.data.data
      })
    },
    getRouteCode(d) {
      const arr = []
      d.map(item => {
        arr.push(item.sortingCode)
      })
      // this.sortCode = arr
      this.condition.sortCode = arr
    },
    getScSortCode(d) {
      const arr = []
      d.map(item => {
        arr.push(item.scSortingCode)
      })
      this.condition.scSortCode2List = arr
    },
    getRouteCode1(d1,d2) {
      console.info(d1,d2,'d1,d2')
      if (d2) {
        this.getArea1(d1,d2)
        this.sortCodeRows[d1.index].sortCode1 = d2.sortingCode
      }else{
        this.queryRouteCode1()
      }
      
    },
    getProcessStatus(){
      this.$http.get('/exportReport/getExportProcessStatus/PageBatchUpdateSortCode').then(res => {
        this.items = res.data.data
      })
    },
    mouseScroll(event) { // google 浏览器下
      // 向上滚 event.wheelDelta = 120
      // 向下滚 event.wheelDelta = -120
      if (event.wheelDelta > 0){
        this.isTbox = true
      } else {
        this.isTbox = false
      }
    },
    // 默认日期格式转换
    dateFormats() {
      const fromDate = new Date()
      let datetime = fromDate.getFullYear() + "-" +
      (fromDate.getMonth()> 8 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
      + (fromDate.getDate()> 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))
      + ' ' + '23:59:59'

      const toDate = new Date((new Date).setDate((new Date).getDate() - 2))
      let datetime1 = toDate.getFullYear() + "-" +
      (toDate.getMonth()> 8 ? (toDate.getMonth() + 1) : "0" + (toDate.getMonth() + 1)) + "-"
      + (toDate.getDate()> 9 ? (toDate.getDate()) : "0" + (toDate.getDate()))
      + ' ' + '00:00:00'

      this.condition.shipmentDateFrom = datetime1
      this.condition.shipmentDateTo = datetime
    },
    // 查询列表
    queryList() {
      this.loadingList = true
      this.isBusy= true
      this.$http.post('/jobkes/search', this.condition).then(res => {
        // console.log(res.data.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.getStatusCount()
        this.loadingList = false
        this.isBusy = false
       let tkIDs = info.content.map(item=>{
         return item.tkID
       })
        this.$http.post('/jobkes/getTags',tkIDs).then(res => {
          let tags = res.data.data
          console.info(tags,'tags')
          for (let i = 0; i < this.rows.length; i++) {
            tags.find(val=>{
              if(val.tkID == this.rows[i].tkID){
                this.rows[i].tag.push(val.tag)
                this.rows[i].tag =  this.rows[i].tag.filter((n) => n);
              }
            })
            this.rows[i].tag =  this.rows[i].tag.filter((n) => n);
          }
          
        })
        
        console.info(info.content)
        // if (this.rows.length > 0) {
        //   this.onDetail(this.rows[0])
        // } else {
        //   this.onDetail(null)
        // }
      }).catch(error => console.log(error))
    },
    // 首次查询
    getList() {
      this.dateConfig.dateFormat = 'Y-m-d'
      this.dateFormats()
      this.queryList()
    },
    // 根据日期查询
    onChange(selectedDates, dateStr, instance) {
      if (selectedDates.length == 1) {
        let later = new Date(selectedDates[0].getTime() + 86400000 * 31)
        instance.config.minDate = selectedDates[0].toUTCString()
        instance.config.maxDate = later.toUTCString()
      } else if (selectedDates.length == 2) {
        instance.config.minDate = undefined
        instance.config.maxDate = undefined
        this.condition.shipmentDateFrom = this.formatDate(selectedDates[0]) + ' 00:00:00'
        this.condition.shipmentDateTo = this.formatDate(selectedDates[1]) + ' 23:59:59'
        this.condition.eadDateFrom = null
        this.condition.eadDateTo = null
        this.condition.inboundDateFrom = null
        this.condition.inboundDateTo = null
        this.eadDate = ""
        this.queryList()
      }
    },
    onChangeEad(selectedDates, dateStr, instance) {
      if (selectedDates.length == 1) {
        let later = new Date(selectedDates[0].getTime() + 86400000 * 7)
        instance.config.minDate = selectedDates[0].toUTCString()
        instance.config.maxDate = later.toUTCString()
      } else if (selectedDates.length == 2) {
        instance.config.minDate = undefined
        instance.config.maxDate = undefined
        this.condition.eadDateFrom = this.formatDate(selectedDates[0]) + ' 00:00:00'
        this.condition.eadDateTo = this.formatDate(selectedDates[1]) + ' 23:59:59'
        this.condition.shipmentDateFrom = null
        this.condition.shipmentDateTo = null
        this.condition.inboundDateFrom = null
        this.condition.inboundDateTo = null
        this.time = ""
        this.queryList()
      }
    },
    onChangeInb(selectedDates, dateStr, instance) {
      if (selectedDates.length == 1) {
        let later = new Date(selectedDates[0].getTime() + 86400000 * 31)
        instance.config.minDate = selectedDates[0].toUTCString()
        instance.config.maxDate = later.toUTCString()
      } else if (selectedDates.length == 2) {
        instance.config.minDate = undefined
        instance.config.maxDate = undefined 
        this.condition.inboundDateFrom = this.formatDate(selectedDates[0]) + ' 00:00:00'
        this.condition.inboundDateTo = this.formatDate(selectedDates[1]) + ' 23:59:59'
        this.condition.eadDateFrom = null
        this.condition.eadDateTo = null
        this.condition.shipmentDateFrom = null
        this.condition.shipmentDateTo = null
        this.time = ""
        this.queryList()
      }
    },
    // 根据ID查询
    searchById() {
      this.selected = ''
      if (this.trackingIDs.trim() !== ''){
        this.condition.shipmentDateFrom = null
        this.condition.shipmentDateTo = null
        this.condition.eadDateFrom = null
        this.condition.eadDateTo = null
        this.condition.page = '1'
        this.time = null
        this.eadDate = null

        this.condition.orderIds = null
        this.condition.trackingIDs = null
        this.condition.udf1 = null
        this.condition.refTkIDs = null
        if (this.searchType === 'Tracking Number') {
          this.condition.trackingIDs = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
          this.condition.orderIds = null
          this.condition.udf1 = null
        } else if (this.searchType === 'Reference Number') {
          this.condition.orderIds = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
        } else if (this.searchType === 'User Define Value') {
          this.condition.udf1 = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
        }else if(this.searchType === '3RD Tracking Number'){
          this.condition.refTkIDs = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
        }
      } else {
        if (this.time !== '') {
        //  this.onChange()
        } else {
          this.dateFormats()
        }
        this.condition.trackingIDs = null
        this.condition.orderIds = null
        this.condition.udf1 = null
        this.condition.refTkIDs = null
      }
      this.queryList()
    },
    handleChangePage(page) {
      this.condition.page = page.toString()
      this.nums = 1
      this.queryList()
    },
    handlePageChange(active) {
      this.condition.page = '1'
      this.condition.size = active
      this.queryList()
    },
    onClear() {
      this.latestOrderStatus1 = null
      this.condition.tags = null
      this.tags = null
      this.condition.orgName = null
      this.condition.orgIds = null
      this.pkg = null
      this.type = null
      this.condition.deliveryType = null
      this.cusType = null
      this.customer = null
      this.area = null
      this.sortCode = null
    },
    // 点击查看详情
    onDetail(obj) {
      if (obj) {
        // this.tkid = obj.tkID
        // this.orderId = obj.orderID
        this.selected = obj.tkID
        this.selectedItem = obj
        this.getjobDetails(obj.orderID)
        this.searchJobkes(obj.tkID)
        this.jobTrack1(obj.tkID)
        this.getTags(obj.tkID)
        this.packageDetail(obj.customerOrderNo)
        // this.jobTrack2(obj.tkID)
        this.getPackageProducts(obj.orderID)
        this.$refs['modal-details'].show()
      } else {
        this.tkid = ""
        this.orderId = ""
        this.selected = ""
        this.selectedItem = null
      }
    },
    getjobDetails(oId) {
      this.$http.get('/jobkes/getjobDetails', { params: { orderId: oId } }).then(res => {
        this.jobDetails1 = res.data.data
      })
    },
    getTags(tkid){
      this.$http.post('/jobkes/getTags',[tkid]).then(res => {
        this.jobTags = res.data.data.map(item=>{return item.tag})
      })
    },
    downloadSelfPick(){
      this.$http.post('/exportReport/exportSelfPickList').then(res => {
        if (res.data.code == 200) {
          window.open(res.data.data,'_self')
        }
      })
    },
    searchJobkes(tkid){
      const para = {}
      para.page = '1'
      para.size = '10'
      para.isAmazon = false
      para.trackingIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobkes/search', para).then(res => {
        if (res.data.data.content.length !== 0){
          this.jobDetails2 = res.data.data.content[0]
        }
      })
    },
    jobTrack1(tkid){
      const para = {}
      para.page = '1'
      para.size = '10'
      para.startEventTime = null
      para.endEventTime = null
      para.statusCode = null
      para.isDisplay = null
      para.orderBy = 'id'
      para.orderMole = '0'
      para.tkIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobTrack/search', para).then(res => {
        this.jobDetails3 = res.data.data.content
      })
    },
    jobTrack2(tkid){
      this.$http.get('/jobTrack/ByOrderStatus', { params:{ tkID: tkid } }).then(res => {
        this.jobDetails4 = res.data.data
      })
    },
    packageDetail(orderNo){
      this.$http.get('/jobkes/getPackWeight', { params: { customerOrderNo: orderNo } }).then(res => {
        this.packageInfo = res.data.data
      })
    },
    getPackageProducts(oId){
      this.$http.get('/jobkes/getPackageProducts', { params: { orderId: oId } }).then(res => {
        this.jobDetails5 = res.data.data
      })
    },
    // query tags
    configTags() {
      // this.$http.get('/configTags/findALLConfigTagsByisEnabled').then(res => {
      //   this.tagsList = res.data.data
      // })
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'tags'}}).then(res => {
        this.tagsList = res.data.data
      })
    },
    selTag(t) {
      const arr = []
      t.map(item => {
        arr.push(item.codeName)
      })
      this.condition.tags = arr
    },
    selStatus(t) {
      const arr = []
      t.map(item => {
        arr.push(item.code)
      })
      this.condition.latestOrderStatus = arr
    },
    // query service party
    querySparty() {
      // this.$http.get('jobkes/getServicePartyList').then(res => {
      //   this.sPartyList = res.data.data
      // })
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'serviceParty'}}).then(res => {
        this.sPartyList = res.data.data
      })
    },
    selSParty(d) {
      const arr = []
      d.map(item => {
        arr.push(item.codeName)
      })
      // this.condition.serviceParty = p.serviceParty
      this.condition.serviceParty = arr
      // this.serviceParty = d.codeDescription
    },
    // queryCustomer() {
//       this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
//          this.customerList = res.data.data.map(res=>{
//           let arr = {id:res.id,orgCode:res.orgCode, info:res.orgCode+' - '+res.displayName+' - '+res.keAccountNumber,name:res.name}
//           return arr
//         })
//       })
    // },
    queryCustomer(d) {
      let condition = d?d:null
      this.$http.get('/admin/settings/organization/getCacheOrganization?condition='+condition).then(res => {
         this.customerList = res.data.data
      })
      
    },
    getCustomer(d){
      const arr = []
      d.map(item => {
        arr.push(item.key)
      })
      this.condition.orgIds = arr
    },
    queryCusType() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'orgType'}}).then(res => {
        this.cusTypeList = res.data.data
      })
    },
    selCusType(d) {
      // this.cusType = d.codeDescription
      // this.condition.customerType = d.codeName
      const arr = []
      d.map(item => {
        arr.push(item.codeName)
      })
      this.condition.customerType = arr
    },
    // 查询status次数
    getStatusCount1() {
      this.$http.post('jobkes/getTotalStatusList', this.condition).then(res => {
        const result = res.data.data
        this.orderStatusCode.comp = (result.COMP) !== 'undefined' ? result.COMP : 0
        this.orderStatusCode.rec = (result.REC) !== 'undefined' ? result.REC : 0
        this.orderStatusCode.dip = (result.DIP) !== 'undefined' ? result.DIP : 0
        this.orderStatusCode.prod = (result.PROD) !== 'undefined' ? result.PROD : 0
      })
    },
    getStatusCount() {
      if (this.trackingIDs.trim() !== ''){
        this.condition.shipmentDateFrom = null
        this.condition.shipmentDateTo = null
        // this.getStatusCount1()
      } else {
        // this.getStatusCount1()
      }
    },
    selnum(d) {
      this.numtype = d
      if (d === 'Tracking Number') {
        this.placeholder = 'Enter Tracking Number to search'
        this.searchType = d
      } else if (d === 'Reference Number') {
        this.placeholder = 'Enter Order Number to search'
        this.searchType = d
      } else if (d === 'User Define Value') {
        this.placeholder = 'Enter User Define Value to search'
        this.searchType = d
      }else if(d=='3RD Tracking Number'){
        this.placeholder = 'Enter 3RD Tracking Number to search'
        this.searchType = d
      }
    },
    selType(d) {
      if (d) {
        this.condition.deliveryType = d.key
        this.type = `${d.des}`
      }
      console.log(d)

    },
    getTypeText(d) {
      if (d) {
        return d.des
      }
    },
    selSonList(d,i) {
      console.log(d,i)
      this.tkid = d.tkID
      this.orderId = d.orderID
      this.nums = d.pkSN
      this.idx = i
    },
    // 全选 全不选
    selectedAll() {
      this.rows.map(i=>{
        !this.isAllCheck? (i.choose=false) : (i.choose=true)
      })
    },
    selectedAll1() {
      if (this.isAllCheck1) {
        this.check1 = true
        this.check2 = false
      }
    },
    itemCheck(item){
      // batch sortCode
      this.sortCodeRows.push(item.item)
      // batch label
      let arrTrue=[]
      let arrFalse=[]
      this.rows.map(i=>{
        if (i.choose) {
          arrTrue.push(i.choose)
        } else {
          arrFalse.push(i.choose)
        }
      })
      if (arrTrue.length === this.rows.length) {
        this.isAllCheck = true
        this.check1 = true
        this.check2 = false
      }
      if (arrFalse.length >0) {
        // this.isAllCheck = false
        this.check1 = false
        this.check2 = true
      }
      if (arrFalse.length === this.rows.length) {
        this.isAllCheck = false
        this.check1 = true
        this.check2 = false
      }
      const obj = {}
      obj.customerOrderNo = item.item.customerOrderNo
      obj.tkID = item.item.tkID
      obj.labelUrl= (item.item.updateLabelUrl!== null) ? item.item.updateLabelUrl : item.item.labelUrl
      obj.orderQty = item.item.orderQty
      this.arrBox.push(obj)

    },
    batchDownloadPDF(){
      // 
      let customerOrderNo = this.selected.map(e=>{
        return e.customerOrderNo
      })
      if (customerOrderNo.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return false;
      }
      this.$http.post('/jobkes/mergePDF',customerOrderNo).then(res=>{
        if (res.data.code == '200') {
          window.open(res.data.data, "_blank")
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    batchRegenerateSFLabel() {
      if (this.tkIds.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.$http.post('/debug/status/regenerateSFLabel', this.tkIds)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      this.queryList()
    },
    // 批量下载跳转
    batchDownload() {
      if (this.tkIds.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      console.info(this.selectAll,'0-')
      if (this.selectAll) {
        let obj = {}
        obj.all = true
        obj.jobKesByPage = this.condition
        obj.labelUrlReportDtoList = null
        this.batchDownload1(obj)
      } else {
        let obj = {}
        obj.all = false
        obj.jobKesByPage = this.condition
        obj.labelUrlReportDtoList = this.arrBox
        this.batchDownload1(obj)
      }
    },
    batchDownload1(p) {
      this.$http.post('/exportReport/labelUrlReport', p).then(res=>{
        if (res.data.code == '200') {
          this.$router.push({ name: 'batch-download' })
        }
      })
    },
    batchDownloadSignature() {
      if (this.tkIds.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      console.info(this.selectAll,'0-')
      if (this.selectAll) {
        let obj = {}
        obj.all = true
        obj.jobKesByPage = this.condition
        obj.signatureUrlReportDtoList = null
        this.batchDownloadSignature1(obj)
      } else {
        let obj = {}
        obj.all = false
        obj.jobKesByPage = this.condition
        obj.signatureUrlReportDtoList = this.arrBox
        this.batchDownloadSignature1(obj)
      }
    },
    batchDownloadSignature1(p) {
      this.$http.post('/exportReport/signatureUrlReport', p).then(res=>{
        if (res.data.code == '200') {
          this.$router.push({ name: 'batch-download-signature' })
        }
      })
    },
    statusReport() {
      if (this.tkIds.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      if (this.selectAll) {
        let obj = {}
        obj.from = '2022-03-07 00:00:00' // 日期不能为空，假数据
        obj.to = '2022-03-07 00:00:00'
        obj.reportType = 'StatusCode'
        obj.orderStatus = null
        obj.isAmazon = false
        obj.tkIds = this.tkIds
        obj.all = true
        obj.jobKesByPage = this.condition
        this.statusReport1(obj)
      } else {
        let obj = {}
        obj.from = this.condition.shipmentDateFrom
        obj.to = this.condition.shipmentDateTo
        obj.reportType = 'StatusCode'
        obj.orderStatus = null
        obj.all = false
        // obj.orgIds = ["org001"]
        obj.isAmazon = false
        obj.tkIds = this.tkIds
        this.statusReport1(obj)
      }
    },
    statusReport1(obj) {
      this.$http.post('/exportReport/publish', obj).then(res=> {
        if (res.data.code == '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'batch-update' })
        }
      })
    },
    requestRSS() {
      this.$http.post('/operation/againRss', this.rssList).then(res=> {
        if (res.data.code == '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    debugUse(){
      this.debugUser = ''
      if (this.tkIds.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.isDebugUse = true
    },
    saveDebug(){
      if (this.debugUser.frontEndname.includes('SF')) {
        this.$http.post('/test/batchSF', this.tkIds).then(res=> {
        })
      }else if(this.debugUser.frontEndname.includes('ZEEK')){
        this.$http.post('/test/zeek',this.tkIds).then(res => {
        })
      }else if(this.debugUser.frontEndname.includes('EPOD')){
        this.$http.post('/debug/status/debugEpordService', this.tkIds).then(res=> {
        })
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      this.isDebugUse = false
      this.queryList()
    },
    // queryRouting() {
    //     this.$http.get('/routeService/routingrulegroup').then(res => {
    //       res.data.data.find(item=>{
    //         if (item.label.includes('Order to 3rd Party')) {
    //           this.debugUseList = item.options
    //         }
    //       })
    //     })
    //   },
    orderDetailReportV2(){
      if (this.orgIds.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      let obj = {}
      if (this.selectAll) {
        obj.from = '2022-03-07 00:00:00' // 日期不能为空，假数据
        obj.to = '2022-03-07 00:00:00'
        obj.reportType = 'OrderDetailReportV2'
        obj.orderStatus = null
        obj.isAmazon = false
        obj.all = true
        obj.jobKesByPage = this.condition
      }else{
        obj.from = this.condition.shipmentDateFrom
        obj.to = this.condition.shipmentDateTo
        obj.reportType = 'OrderDetailReportV2'
        obj.orderStatus = null
        obj.isAmazon = false
        obj.all = false
        obj.orgIds = this.orgIds
      }
      this.$http.post('/exportReport/exportOrdersDetailListV2',obj).then(res=> {
        if (res.data.code == '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'batch-update' })
        }
      })
    },
    orderDetailReport(){
      if (this.orgIds.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the order',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      let obj = {}
      if (this.selectAll) {
        obj.from = '2022-03-07 00:00:00' // 日期不能为空，假数据
        obj.to = '2022-03-07 00:00:00'
        obj.reportType = 'OrderDetailReport'
        obj.orderStatus = null
        obj.isAmazon = false
        obj.all = true
        obj.jobKesByPage = this.condition
      }else{
        obj.from = this.condition.shipmentDateFrom
        obj.to = this.condition.shipmentDateTo
        obj.reportType = 'OrderDetailReport'
        obj.orderStatus = null
        obj.isAmazon = false
        obj.all = false
        obj.orgIds = this.orgIds
      }
      this.$http.post('/exportReport/exportOrdersDetailList',obj).then(res=> {
        if (res.data.code == '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'batch-update' })
        }
      })
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
    },
    getAccess() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      if (userData.organizationName == 'KEC') {
        this.isAccess = true
        this.isAccess2 = true
      } else {
        this.isAccess = false
        this.isAccess2 = false
      }
    },
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }
  },
}
</script>
<style scoped>
.ll-fail{
  margin: auto;
}
.ll-switch{
  width: 100px;
}
.ll-cardHei1>.card-body{
  padding: 0;
}
.ll-tBox{
  /* position: fixed; */
  height: 46px;
  z-index: 9;
  /* width: 400px; */
  background-color: #fff;
  padding-left: 0.5rem;
}
.ll-cbox{
  margin-top: 10px;
  overflow-y: auto;
  border-top: 1px solid #d9d9d9;
}
.ll-batch{
  /* position: absolute;
  right: 14rem;
  z-index: 9;
  top: 6rem; */
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem 0;
}
.ll-badge{
  /* padding: 2px; */
  margin-right: 2px;
  font-size: 14px;
}
.ll-searchBtn div{
  background-color: #ed6d01;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-searchBtn div:active{
  opacity: 0.8;
}
.ll-fHei{
  /*height: 124px;*/
  height: var(--height);
  margin-bottom: 10px;
}
.icon{
  width: 2em;
  height: 2em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.ll-conBox{
  display: flex;
  align-items: center;
}
.ll-box{
  margin-left: 10px;
}
.ll-box span{
  display: block;
  text-align: center;
}
.ll-newbox{
  text-align: center;
}
.ll-newbox .ll-tit{
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
}
.ll-fHei>.card-body{
  display: flex;
  align-items: center;
}
.ll-cardBody{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ll-card>.card-body{
  padding: 10px 5px;
  border-radius: 5px;
}
.ll-fpickr {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 7px;
}
.ll-tabs .nav-pills .nav-link.active{
  background-color: unset;
  color: #5e5873;
  box-shadow: none;
  border: none;
  border-bottom: 3px solid #ed6d01;
  border-radius: 0px;
}
.ll-tabs .nav-item{
  font-weight: bold;
}
.ll-btn{
  bottom: 10px;
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 10px;
}
.ll-tkid{
  color: #161d31;
}
.ll-cardList{
  margin-bottom: 5px;
}
.ll-cardList .card-header{
  padding: 0.5rem;
}
.ll-cardList .ll-cardBody{
  padding: 0.5rem;
}
.ll-cardHei{
  /* height: 100%; */
  overflow-y: auto;
}
.ll-page{
  position: absolute;
  bottom: -10px;
  /* left: 15%; */
}
.ll-filter {
  padding: 0 1rem 0 1rem;
}
.ll-filter .v-select {
  margin-top: 7px;
}
.ll-cardActive{
  background: #f6f6f6;
}
.ll-backg{
  /* background: #f6f6f6; */
  height: 85vh;
}
.ll-0+ div{
  display: none;
}
.ll-rowbox{
  width: 100%;
  margin-left: 0;
}
.ll-rowbox .ll-col1{
  text-align: left;
  padding: 0;
  font-size: 13px;

}
.ll-rowbox .ll-col2{
  text-align: left;
  padding: 0;
    font-size: 13px;
  font-weight: 700;
}
.ll-rowbox .ll-col3{
  text-align: left;
  padding: 0;
    font-size: 13px;

}
.ll-rowbox .ll-col4{
  text-align: left;
  padding: 0;
    font-size: 13px;

}
.ll-rowbox .ll-col5{
  text-align: left;
  padding: 0;
    font-size: 13px;

}
.ll-rowbox .ll-col6{
  text-align: left;
  padding: 0;
    font-size: 13px;

}
.ll-custom{
  padding: 0 10px;
}
.ll-numSel{
  height: auto;
}
.ll-pg{
  width: 30%;
}
.ll-outline> div{
  background: var(--background);
  margin-right: 1px;
}
.ll-hover{
  cursor: pointer;
}
/* .ll-hover:hover{
  color: #ed6d01;
} */
.ll-fontbold{
  font-weight: bold;
}

</style>
<style>
[dir] .table-hover tbody tr{
  font-size: 14px !important;
}
[dir] .table td{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
[dir] .table.b-table > tbody .b-table-row-selected.table-active td{
  background-color: unset;
  background: unset;
}
[dir] .table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr{
  background-color: unset;
  background: unset;
}
.ll-eltable th{
  padding: 0;
}
.ll-sortCodeBox{
  width: 20%;
}
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
  vertical-align: middle;
}
.ll-tabs .nav-item{
  font-weight: bold;
}
.ll-collapse .card{
  box-shadow: none;
}
.ll-text{
  margin: auto;
}
[dir] .custom-select{
  border-radius: 0;
}
[dir] .input-group:not(.bootstrap-touchspin):focus-within .form-control,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .input-group-text,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .custom-select{
  border-color: #ed6d01;
  box-shadow: none;
}
.ll-row{
  flex-wrap: nowrap;
}
.action-button {
  margin: 10px;
}
.ll-rowbox.order-detail {
  padding: 10px;
  border-radius: 0.428rem;
  font-size: 14px;
  margin-top: 10px;
}
.card.card-browser-states.ll-card.ll-cardList.ll-hover.order-detail.bg-white {
  box-shadow: none;
  font-size: 12px;
  border-radius: 5px;
  margin: 10px 0px;
}
.card.card-browser-states.ll-card.ll-cardList.ll-hover.order-detail.bg-white .card-body:hover {
  background: #eeeeee;
}
.modal-lg {
  max-width: 90%;
}
/* .modal-content {
  height: 90vh;
} */
.ll-tkid.column-title{
  font-weight: bold;
  font-size: 14px;
}
td.small-cell {
  padding-left: 40px !important;
  width: 10%;
}
td.extra-small-cell {
  padding-left: 13px !important;
  width: 5%;
}
td.small-cell span {
  padding-left: 7px;
}
.ll-wrapper {
  height: 35vh !important;
}
.ll-tableHei {
  height: 30vh !important;
}
.badge-tag {
  background: #c95300fa;
  color: #fff;
  margin-right: 4px !important;
  margin-bottom: 3px;
}
.tag-set {
  float: right;
  margin-top: 10px;
}
</style>
