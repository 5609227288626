<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <div id="my-captcha-container">
          <!-- The contents of this container will be replaced by the captcha widget -->
      </div>
      <!-- Login v1 -->
      <b-card class="mb-0 ll-loginbox">
        <!-- <b-link class="kerry-logo" v-if="host > -1">
        </b-link> -->
        <b-img  v-if="host > -1"
          :src="require('@/assets/images/imgs/kerryexpress.svg')"
          fluid
          style="max-width: unset !important;width: 60%; !important;margin-left:20%"
          alt="logo"
        />
        <b-link class="brand-logo" v-else>
        </b-link>
        <!-- form -->
        <validation-observer
          ref="loginForm"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <validation-provider :name="$t('username')" :rules="{required:true}">
              <!-- username -->
              <b-form-group slot-scope="{ valid, errors }">
                <div class="d-flex justify-content-between">

                <label for="username">Username</label>
                {{ errors[0] }}
                </div>
                <b-form-input
                  id="username"
                  v-model="userInfoForm.username"
                  name="login-username"
                  placeholder="Username"
                  ref="username"
                  autofocus
                  @keyup.enter="focusNext('newPassword')"
                />
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('password')" :rules="{required:true}">
              <!-- password -->
              <b-form-group slot-scope="{ valid, errors }">
                <div class="d-flex justify-content-between">
                  <label for="newPassword">New Password</label>
                  <b-icon-question-circle class="ml-1" id="tooltip-target-1"></b-icon-question-circle>
                  {{ errors[0] }}
                  <b-tooltip target="tooltip-target-1" triggers="hover">
                    <b><u>Password requirements (密碼要求):</u></b><br>
                    1. 12-20 characters (密碼長度應為12-20個字符之間)<br>
                    2. Contain Uppercase letter, Lowercase letter, Numbers, and Symbols (必需包含大寫字母, 小寫字母, 數字, 符號)<br>
                    3. Cannot be the same as any of the last 24 passwords used (不能與前24個密碼相同)
                  </b-tooltip>
                </div>
                  <b-input-group
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="newPassword"
                      v-model="userInfoForm.newPassword"
                      :type="passwordFieldType1"
                      class="form-control-merge"
                      name="login-new-password"
                      placeholder="New Password"
                      ref="newPassword"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility1"
                      />
                    </b-input-group-append>
                  </b-input-group>
              </b-form-group>
            </validation-provider>
            <validation-provider :name="$t('password')" :rules="{required:true}">
              <!-- password -->
              <b-form-group slot-scope="{ valid, errors }">
                <div class="d-flex justify-content-between">
                  <label for="repeatNewPassword">Repeat the New Password</label>
                  {{ errors[0] }}

                </div>
                  <b-input-group
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="repeatNewPassword"
                      v-model="userInfoForm.repeatNewPassword"
                      :type="passwordFieldType2"
                      class="form-control-merge"
                      name="login-repeat-new-password"
                      placeholder="Repeat Password"
                      ref="repeatNewPassword"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility2"
                      />
                    </b-input-group-append>
                  </b-input-group>
              </b-form-group>
            </validation-provider>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
                v-if="false"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              @click.prevent="recaptcha"
              :disabled="disabled"
              :style="{'cursor': disabled ? 'no-drop' : 'pointer'}"
            >
              Reset Password
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>

    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BImg,
  BIconQuestionCircleFill,
  BTabs,
  BTab,
  BIconQuestionCircle
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility,togglePasswordVisibility1,togglePasswordVisibility2 } from '@core/mixins/ui/forms'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debounce } from "@/libs/fun.js"

export default {
  components: {
    BImg,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BTabs,
    BTab,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BIconQuestionCircleFill,
    BIconQuestionCircle,
  },
  mixins: [togglePasswordVisibility,togglePasswordVisibility1,togglePasswordVisibility2],
  props: {
    username: String,
    mfaMethod: String,
    mfaContact: String,
    mfaCode: String,
    email: String,
    phone: String,
  },
  data() {
    return {
      userInfoForm: {
        username: null,
        deviceId: null,
        email: null,
        phone: null,
        mfaMethod: null,
        mfaContact: null,
        mfaCode: null,
        newPassword: null,
        repeatNewPassword: null,
      },
      status: '',
      // validation rules ordering.hk.kerryexpress.com
      required,
      disabled: null,
      disableResend: true,
      disableCount: 60,

      host:window.location.host.indexOf('ordering.hk.kerryexpress.com'),
      tabIndex: 1
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    'userInfoForm.username'(val) {
      if (val !== '') {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    'userInfoForm.newPassword'(val) {
      if (val !== '') {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    'userInfoForm.repeatNewPassword'(val) {
      if (val !== '') {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
  },
  created() {
    // assign username and password from props and focus on newPassword field
    this.userInfoForm.username = this.username
    this.userInfoForm.email = this.email
    this.userInfoForm.phone = this.phone
    this.userInfoForm.mfaMethod = this.mfaMethod
    this.userInfoForm.mfaContact = this.mfaContact
    this.userInfoForm.mfaCode = this.mfaCode
  },
  mounted() {
    sessionStorage.clear()
    // localStorage.clear()
    this.getCookie()
    this.$nextTick(() => this.$refs.newPassword.focus())
  },
  destroyed() {},
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "doLogin".
      // const token = await this.$recaptcha('doLogin')
      // console.log(token)
      this.doReset()
    },
    getCookie() {

    },
    focusNext() {
      this.$refs.newPassword.focus()
    },
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          // this.doReset()
          this.recaptcha()
        }
      })
    },
    doReset() {
      this.disableCount = 60
      this.disableResend = true
      this.$http.post('/user/forgotPassword', this.userInfoForm).then(res => {
        if (res.data.message == "1003" || res.data.message == "1000" || res.data.message == "1001") {
        } else if (res.data.code != 200) {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: res.data.message,
          //     icon: 'XIcon',
          //     variant: 'danger',
          //   },
          // })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Reset Password Success, please login with the new password',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'auth-login' })
        }
      }).catch(error => {
        // console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Server is bussy now, please try again later',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style scoped>
.ll-loginbox > .card-body{
  padding: 5rem 1.5rem;
}
.brand-logo {
  width: 100%;
  height: 70px;
}
.kerry-logo{
  width: 100% !important;
  height: 70px !important;
}
.kerry-mfa-logo{
  text-align: center;
  width: 100% !important;
  height: 50px !important;
  background: url('../../assets/images/imgs/kerryexpress.svg') no-repeat center !important;
  background-size: 50% !important;

}
.ll-loginbox .kerry-logo{
  background: url('../../assets/images/imgs/kerryexpress.svg') no-repeat center !important;
  background-size: 80%;
}
.ll-loginbox .brand-logo{
  background: url('../../assets/images/imgs/loginLogo.svg') no-repeat center;
  background-size: 80%;
}
.orange-text {
  color: #FB7800 !important;
  background: transparent !important;
}

.custom-tooltip > .tooltip-inner {
  max-width: 1000px !important;
  width: 500px !important;
}
</style>
