<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0 ll-loginbox">
        <!-- logo -->
        <b-img  v-if="host > -1"
            :src="require('@/assets/images/imgs/kerryexpress.svg')"
            fluid
            style="max-width: unset !important;
            width: 60%; !important;margin-left:20%"
            alt="logo"
        />
        <b-link class="brand-logo" v-else>
        </b-link>

        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your username and email, we'll send you a code to verify your authentication
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- username -->
            <b-form-group
              label-for="username_mfa"
              label="Username"
            >
              <b-form-input
                id="username_mfa"
                v-model="userInfoForm.username"
                name="login-username"
                placeholder="Username"
                autofocus
                ref="username_mfa"
              />
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="email">MFA Method</label>
              </div>
              <b-input-group
              >
                <b-form-radio-group>
                  <b-form-radio
                    v-model="userInfoForm.mfaMethod"
                    name="mfaMethod"
                    value="EMAIL"
                    id="email"
                  />
                  <label for="email" class="switch-label mr-2">Email</label>
                  <b-form-radio
                    v-model="userInfoForm.mfaMethod"
                    name="mfaMethod"
                    value="SMS"
                    id="phone"
                  />
                  <label for="phone" class="switch-label">Mobile Number</label>
                </b-form-radio-group>
                <div class="h2 mb-0 ml-2">
                  <b-icon-question-circle-fill id="mfa-tip" style="color:grey"></b-icon-question-circle-fill>
                  <b-tooltip class="custom-tooltip" target="mfa-tip" triggers="hover">
                    驗證碼發送方式會以客戶首次登記時之選擇送出, 如有更改或需要協助, 請與我們客戶服務部聯絡。<br>The MFA code sending method will be sent according to first customer MFA registration. Please contact our customer service department for any changes or assistance.
                  </b-tooltip>
                </div>
              </b-input-group>
            </b-form-group>

            <b-form-group v-if="showEmail">
              <div class="d-flex justify-content-between">
                <label for="email">Email</label>
              </div>
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  id="email"
                  v-model="userInfoForm.email"
                  class="form-control-merge"
                  name="login-email"
                  placeholder="Email"
                  ref="email"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group v-if="showPhone">
              <div class="d-flex justify-content-between">
                <label for="phone">Mobile Number</label>
              </div>
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  id="phone"
                  v-model="userInfoForm.phone"
                  class="form-control-merge"
                  name="login-phone"
                  placeholder="Mobile Number"
                  ref="phone"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group v-if="showMfaCode">
              <div class="d-flex justify-content-between">
                <label for="mfaCode">Verification Token</label>
              </div>
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  id="mfaCode"
                  v-model="userInfoForm.mfaCode"
                  class="form-control-merge"
                  name="login-mfaCode"
                  placeholder="Verification Token"
                  ref="mfaCode"
                />
              </b-input-group>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              @click.prevent="recaptcha"
              :disabled="disabled"
              :style="{'cursor': disabled ? 'no-drop' : 'pointer'}"
            >
              Submit
            </b-button>
            <b-button
              variant="secondary"
              type="submit"
              block
              @click.prevent="resendMfaCode"
              :disabled="disableResend"
              :style="{'cursor': disableResend ? 'no-drop' : 'pointer'}"
              v-if="showResendMfaCode"
            >
              Resend MFA Code {{ disableCount > 0 ? '(' + disableCount + ')' : '' }}
            </b-button>

          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BIconQuestionCircleFill,
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import {watch} from "@vue/composition-api";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BIconQuestionCircleFill,
    ToastificationContent,
  },
  data() {
    return {
      userInfoForm: {
        username: null,
        deviceId: null,
        email: null,
        phone: null,
        mfaMethod: null,
        mfaContact: null,
        mfaCode: null,
      },
      userEmail: '',
      // validation
      required,
      email,
      disabled: null,
      disableResend: true,
      disableCount: 60,
      showMfaCode: false,
      showResendMfaCode: false,
      showFirstMfa: false,
      showEmail: false,
      showPhone: false,
      modalMessage: '',
      host:window.location.host.indexOf('ordering.hk.kerryexpress.com'),
    }
  },
  watch: {
    'userInfoForm.mfaMethod'(val) {
      if (val === 'EMAIL') {
        this.showEmail = true
        this.showPhone = false
        this.$nextTick(() => this.$refs.email.focus())
      } else {
        this.showEmail = false
        this.showPhone = true
        this.$nextTick(() => this.$refs.phone.focus())
      }
    },
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "doLogin".
      // const token = await this.$recaptcha('doLogin')
      // console.log(token)
      this.doLogin()
    },

    focusNext() {
      this.$refs.pwd.focus()
    },
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          // this.doLogin()
          this.recaptcha()
        }
      })
    },

    resendMfaCode() {
      this.userInfoForm.mfaCode = null
      // this.doLogin()
      this.recaptcha()
    },
    doLogin() {
      this.disableCount = 60
      this.disableResend = true
      if (this.userInfoForm.mfaMethod == 'EMAIL') {
        this.userInfoForm.mfaContact = this.userInfoForm.email
      }
      if (this.userInfoForm.mfaMethod == 'SMS') {
        this.userInfoForm.mfaContact = this.userInfoForm.phone
      }
      this.$http.post('/user/forgotPassword', this.userInfoForm).then(res => {
        const info = res.data.data
        if (res.data.message == "1003") {
          this.$router.push({ name: 'fUpdatePassword', params: {
            username: this.userInfoForm.username,
            email: this.userInfoForm.email,
            phone: this.userInfoForm.phone,
            mfaMethod: this.userInfoForm.mfaMethod,
            mfaContact: this.userInfoForm.mfaContact,
            mfaCode: this.userInfoForm.mfaCode,
          }})

          // redirect to reset password page
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Please reset your password",
              icon: 'CrosshairIcon',
              type: 'info',
            },
          })

        }
        // if require mfa code, show mfa code input
        else if (res.data.message == "1000" || res.data.message == "1001") {
          this.showMfaCode = true
          this.showResendMfaCode = true
          // count down 60s to make resend button enable
          this.disableCount = 60
          const timer = setInterval(() => {
            this.disableCount -= 1
            if (this.disableCount <= 0) {
              clearInterval(timer)
              this.disableResend = false
            }
          }, 1000)
          if (res.data.message == "1000") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Please check your email: " + this.userInfoForm.email + " and enter the verification token",
                icon: 'CrosshairIcon',
                type: 'info',
              },
            })
          } else if (res.data.message == "1001") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Please check your mobile number: " + this.userInfoForm.phone + " and enter the verification token",
                icon: 'CrosshairIcon',
                type: 'info',
              },
            })
          }
          this.$nextTick(() => this.$refs.mfaCode.focus())
          return true;
        } else if (res.data.code != 200) {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: res.data.message,
          //     icon: 'XIcon',
          //     variant: 'danger',
          //   },
          // })
          return false;
        } else {
          localStorage.setItem('userInfo', JSON.stringify(info))
          sessionStorage.setItem('token', info.token)
          sessionStorage.setItem('userInfo', JSON.stringify(info))
          // window.document.cookie= JSON.stringify(info)
          // const token = sessionStorage.getItem('token')
          const token = JSON.parse(localStorage.getItem('userInfo')).token
          axios.defaults.headers.Authorization = token
          if (token !== null) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Login Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'dashboard' })
          }
        }
      }).catch(error => {
        // console.log(error)
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Server is bussy now, please try again later',
        //     icon: 'XIcon',
        //     variant: 'danger',
        //   },
        // })
      })
    },
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style scoped>
.ll-loginbox > .card-body{
  padding: 5rem 1.5rem;
}
.brand-logo {
  width: 100%;
  height: 70px;
}
.kerry-logo{
  width: 100% !important;
  height: 70px !important;
}
.kerry-mfa-logo{
  text-align: center;
  width: 100% !important;
  height: 50px !important;
  background: url('../../assets/images/imgs/kerryexpress.svg') no-repeat center !important;
  background-size: 50% !important;

}
.ll-loginbox .kerry-logo{
  background: url('../../assets/images/imgs/kerryexpress.svg') no-repeat center !important;
  background-size: 80%;
}
.ll-loginbox .brand-logo{
  background: url('../../assets/images/imgs/loginLogo.svg') no-repeat center;
  background-size: 80%;
}
.orange-text {
  color: #FB7800 !important;
  background: transparent !important;
}

.custom-tooltip > .tooltip-inner {
  max-width: 1000px !important;
  width: 500px !important;
}
</style>